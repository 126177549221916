export default [
  {
    title: "Profil",
    route: "dashboard",
    icon: "UserIcon",
  },
  {
    title: "Pembuatan SIP",
    route: "pembuatan-sip",
    icon: "FilePlusIcon",
  },
  // {
  //   title: "Duplikat SIP",
  //   route: "duplikat-sip",
  //   icon: "CopyIcon",
  // },
  {
    title: "Perubahan SIP",
    route: "perubahan-sip",
    icon: "RefreshCwIcon",
  },
  {
    title: "Pencabutan SIP",
    route: "pencabutan-sip",
    icon: "XSquareIcon",
  },
  {
    title: "Notifikasi",
    route: "notifikasi",
    icon: "BellIcon",
  },
  {
    title: "Akun",
    route: "akun-sip",
    icon: "SettingsIcon",
  },
  // {
  //   title: "Syarat & Perijinan",
  //   route: "syarat",
  //   icon: "FileIcon",
  // },
  // {
  //   title: "Progress",
  //   route: "progress",
  //   icon: "TrendingUpIcon",
  // },
];
